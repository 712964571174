import React, { useContext } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GridIcon } from "../Icons";
import {
  ProductCard,
  ProductBody,
  ProductFooter,
  ProductSku,
  ProductFigure,
  ProductName,
  CompareIcon,
} from "../ProductStyle";
import { Context } from "../context";
import CheckIcon from "../Icons/CheckIcon";
import PrimaryButton from "../Button/PrimaryButton";
import LightGrayButton from "../Button/LightGrayButton";

const SimilarModal = ({ productData }) => {
  const { compareProducts, addProductToComparison } = useContext(Context);

  const pIds = compareProducts.map((p) => p.id);

  return (
    <ProductCard className="card card-grid">
      <ProductBody className="card-body">
        <ProductFigure className="card-figure">
          <ProductSku className="product-sku">
            SKU : {productData["skuNumber"]}
          </ProductSku>
          <Link to={productData.url}>
            <GatsbyImage
              image={productData.image.gatsbyImageData}
              alt={productData.title}
            />
          </Link>
          {pIds.indexOf(productData.id) >= 0 ? (
            <CompareIcon>
              <PrimaryButton icon={<CheckIcon />} text="Compare" size="xs" />
            </CompareIcon>
          ) : (
            <CompareIcon
              onClick={() => addProductToComparison("card", productData)}
            >
              <LightGrayButton icon={<GridIcon />} text="Compare" size="xs" />
            </CompareIcon>
          )}
        </ProductFigure>
      </ProductBody>
      <ProductFooter className="card-footer">
        <ProductName className="product-name">
          <Link to={productData.url}>{productData.title}</Link>
        </ProductName>
      </ProductFooter>
    </ProductCard>
  );
};
export default SimilarModal;
